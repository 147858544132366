<template>
    <div>
        <div ref="mychildcomponent" hidden id='productDownlaodReport' class="col-md-7">
            <!--HEADER-->
            <div class="col-md-12">
                <table style="width: 100%">
                            <thead v-if="isHeaderFooter=='true'">
                                <tr>
                                    <th>
                                        <!-- <HeaderComponent></HeaderComponent> -->
                                        <!-- <div class="" style=" position:relative;  overflow: hidden !important;">
                                            <img src="header.png" style="width: 100%;height:90px">
                                        </div> -->
                                        <HeaderComponent></HeaderComponent>

                                    </th>
                                </tr>
                            </thead>
                    </table>
            </div>           
            <div class="col-md-12 ">
                <div class="row">

                    <table class="table col-md-12 m-auto">
                        <tr class="heading" style="font-size:14px;">
                            <th class="text-center" style="color:black !important;">#</th>
                            <th class="text-center" style="color:black !important;">{{ $t('Product.Product') }} </th>
                            <th class="text-center" style="color:black !important;">{{ $t('Product.Category') }} </th>
                            <th class="text-center" style="color:black !important;">{{ $t('Product.SalePrice') }}</th>
                            <th class="text-center" style="color:black !important;">Avg.Price</th>
                            <th class="text-center" style="color:black !important;">{{ $t('Product.CurrentStock') }}</th>
                        </tr>
                        <tr style="font-size:13px;" v-for="(item, index) in list" v-bind:key="item.id">
                            <td class="text-center" style="color:black !important;">{{index+1}}</td>
                            <td class="text-center" style="color:black !important;">{{item.englishName}}</td>
                            <td class="text-center" style="color:black !important;">{{item.categoryNameEn}}</td>
                            <td class="text-center" style="color:black !important;">{{item.salePrice}}</td>
                            <td class="text-center" style="color:black !important;">
                                <div v-if="item.inventory != null && item.inventory.averagePrice > 0">
                                            {{ item.inventory.averagePrice }}
                                        </div>

                                        <div v-else >{{ item.inventory == null ? 0 :
                                            item.inventory.averagePrice }}</div>
                            </td>
                            <td class="text-center" style="color:black !important;">
                                <div v-if="item.inventory != null && item.inventory.currentQuantity > 0">
                                            {{ item.inventory.currentQuantity }}
                                        </div>

                                        <div v-else class="badge bg-danger">{{ item.inventory == null ? 0 :
                                            item.inventory.currentQuantity }} {{ $t('Product.LowStock') }}</div>
                                    
                            </td>
                            
                           
                        </tr>

                       
                    </table>
                   
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    export default {
        props: ['printDetails' ],
        data: function () {
            return {
               list:[],
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                Print: false,
                render: 0
            }
        },

        created: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            var root = this;

            if (this.printDetails.length > 0) {
                this.list = this.printDetails;

                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
           
        },
        methods: {
           
            printInvoice: function () {
                this.$htmlToPaper('productDownlaodReport', options, () => {

});
                
                // var form = new FormData();
                // form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
                // var root = this;
                // var token = '';
                // if (root.$session.exists()) {
                //     token = localStorage.getItem('token');
                // }
                // root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                //     .then(function (response) {
                //         const url = window.URL.createObjectURL(new Blob([response.data]));
                //         const link = document.createElement('a');
                //         link.href = url;
                //         var date = moment().format('DD MMM YYYY');


                //         link.setAttribute('download', 'Product Report ' + date + '.pdf');
                //         document.body.appendChild(link);
                //         link.click();

                //         root.$emit('close');
                //     });
            }
        }
    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>