<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" placeholder="Select Expense Type"
            track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true">

            <span slot="noResult" class="btn btn-primary " v-on:click="AddCity('Add')">Add New</span><br />

        </multiselect>

        <modal :show="show" v-if="show">

            <div style="margin-bottom:0px" class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="modal-header" v-if="type == 'Edit'">

                                <h5 class="modal-title" id="myModalLabel"> Update Expense Category</h5>

                            </div>
                            <div class="modal-header" v-else>

                                <h5 class="modal-title" id="myModalLabel"> Add Expense Category</h5>

                            </div>
                            <div class="text-left">
                                <div class="card-body">
                                    <div class="row">

                                        <div  class="form-group has-label col-sm-12 "
                                            v-bind:class="{ 'has-danger': $v.brand.name.$error }">
                                            <label class="text  font-weight-bolder">
                                                Name: <span class="text-danger"> *</span>
                                            </label>
                                            <input class="form-control" v-model="$v.brand.name.$model" type="text" />
                                            <span v-if="$v.brand.name.$error" class="error">
                                                <span v-if="!$v.brand.name.required"> {{ $t('addBrand.NameRequired')
                                                }}</span>
                                                <span v-if="!$v.brand.name.maxLength">{{ $t('addBrand.NameLength') }}</span>
                                            </span>
                                        </div>

                                        <div class="form-group has-label col-sm-12 "
                                            v-bind:class="{ 'has-danger': $v.brand.description.$error }">
                                            <label class="text  font-weight-bolder"> {{ $t('addBrand.Description') }}:
                                            </label>
                                            <textarea rows="3" class="form-control" v-model="$v.brand.description.$model"
                                                type="text" />
                                            <span v-if="$v.brand.description.$error" class="error">
                                                {{ $t('addBrand.descriptionLength') }}
                                            </span>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <div class="checkbox form-check-inline mx-2">
                                                <input type="checkbox" id="inlineCheckbox1" v-model="brand.active">
                                                <label for="inlineCheckbox1"> {{ $t('addBrand.Active') }} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type == 'Edit'">

                                <button type="button" class="btn btn-primary  " v-on:click="SaveCity"
                                    v-bind:disabled="$v.brand.$invalid"> {{ $t('CityDropdown.Update') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{
                                    $t('CityDropdown.btnClear') }}</button>

                            </div>
                            <div class="modal-footer justify-content-right" v-else>

                                <button type="button" class="btn btn-primary  " v-on:click="SaveCity"
                                    v-bind:disabled="$v.brand.$invalid"> {{ $t('CityDropdown.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{
                                    $t('CityDropdown.btnClear') }}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </modal>
    </div>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators"
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'
export default {
    name: 'citydropdown',
    props: ["values"],
    mixins: [clickMixin],

    components: {
        Multiselect,

    },
    data: function () {
        return {
            options: [],
            value: '',
            show: false,
            type: '',
            brand: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    active: true
                },
            render: 0
        }
    },
    validations: {
        brand: {
            name: {
                required,
                maxLength: maxLength(250)
            },
            code: {

                maxLength: maxLength(30)
            },
            
            description: {
                maxLength: maxLength(500)
            }
        }
    },
    methods: {
        getData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/PurchasePost/GetExpenseCategoryListQuery?isDropDown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.options = []
                    response.data.results.forEach(function (cat) {
                        root.options.push({
                            id: cat.id,
                            name: cat.name
                        })
                    })
                }
            }).then(function () {
                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })
            });
        },
        AddCity: function (type) {
            this.$v.$reset();
            this.city = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                description: '',
                active: true
            }

            this.show = !this.show;
            this.type = type;
        },
        close: function () {
            this.show = false;
        },

        SaveCity: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/PurchasePost/SaveExpenseCategory', this.brand, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.show = false;
                    root.getData();
                }

            });
        }
    },
    computed: {
        DisplayValue: {
            get: function () {
                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {
                this.value = value;
                this.$emit('input', value.id);
            }
        }
    },
    mounted: function () {
        this.getData();
    },
}
</script>